import React, { useState, useCallback, useEffect } from 'react';
import styles from './Variants.module.css';
import { sortSize } from '../../util/sortSize';


const getColorStyle = (color) => {
  const isHex = (value) => /^#([0-9A-F]{3}){1,2}$/i.test(value);
  const isRGB = (value) => /^rgb\(\d{1,3},\s?\d{1,3},\s?\d{1,3}\)$/i.test(value);
  const isRGBA = (value) => /^rgba\(\d{1,3},\s?\d{1,3},\s?\d{1,3},\s?(0|0?\.\d+|1)\)$/i.test(value);

  let colorValue = color?.value?.trim();
  if (colorValue && !colorValue.startsWith('#') && colorValue.length === 6 && isHex(`#${colorValue}`)) {
    colorValue = `#${colorValue}`;
  }

  if (!isHex(colorValue) && !isRGB(colorValue) && !isRGBA(colorValue)) {
    colorValue = '#000';
  }

  return { backgroundColor: colorValue };
};

const getBorderStyle = (colorValue) => (
  ['#fff', '#ffffff', 'white'].includes(colorValue) ? { borderColor: '#E4E4E4', width: '35px', height: '35px' } : {}
);

const getDefaultVariants = (variants) => {
  const variantOptions = variants?.reduce((acc, option) => { 
    const type = (option?.name || option?.optionType || option?.option_type || '').toLowerCase();
    const updatedType =  type;

    if (!acc[updatedType]) acc[updatedType] = [];
    acc[updatedType].push(
      ...((option?.choices || [])
        .filter(choice => choice?.visible)
        .map(choice => {
          return ({ ...choice, available: true });
        })));
    return acc;
  }, {});
  return variantOptions || [];
}


const getMappedVariants = (variants, availableChoices, selectedVariant = null, selectedValues) => {
  // If no selected variant or all are empty, return default variants
  const selectedVariantValues = Object.values(selectedVariant || {});
  if (selectedVariantValues.every(value => !value || value === "")) {
    return getDefaultVariants(variants);
  }

  // Initialize the mappedVariants object
  const mappedVariants = {};

  // Step 1: Populate mappedVariants with visible choices from the options
  variants?.forEach(option => {
    const type = (option?.name || option?.optionType || option?.option_type || '').toLowerCase();
    const updatedType =  type;

    if (!mappedVariants[updatedType]) {
      mappedVariants[updatedType] = [];
    }

    option?.choices?.forEach(choice => {
      if (choice.visible) {
        mappedVariants[updatedType].push({ ...choice, available: true, variantHavingNoStock: [] });
      }
    });
  });

  // Step 2: Process selected variant and update mappedVariants
  const selectedKeys = Object.keys(selectedVariant);
  selectedKeys.forEach(selectedKey => {
    const selectedValue = selectedVariant[selectedKey]?.description;

    if (selectedValue) {
      // Filter available choices based on selected key and value
      const filteredChoices = availableChoices.filter(choice =>
        choice[selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1)] === selectedValue
      );

      // Create a map of combinations, filtered by the selected key and value
      const availableCombinations = filteredChoices.flatMap(choice =>
        Object.keys(choice)
          .filter(key => key !== selectedKey && key !== 'quantity')
          .map(key => ({
            choice: choice[key],
            quantity: choice.quantity,
            variant: key,
            isSelected: Object.values(selectedValues).includes(choice[key])
          }))
      );

      // Step 3: Update each variant's 'variantHavingNoStock' status based on the selected variants
      const availableCombinationsArray = availableCombinations.filter(comb => comb.quantity === 0).map(comb => comb.choice);

      Object.keys(mappedVariants).forEach(variantType => {
        if (variantType !== selectedKey) {
          mappedVariants[variantType].forEach(variantChoice => {
            // Update the 'variantHavingNoStock' property to reflect out-of-stock choices
            variantChoice.variantHavingNoStock = availableCombinationsArray.filter(value => value === variantChoice.description);
          });
        }
      });
    }
  });

  return mappedVariants;
};



const Variants = ({ variants = [], selectedVariant = {}, variantHandler, availableChoices = [], childListings = [], selectedListing = {} }) => {

  const [selectedValues, setSelectedValues] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(false)

  const changeHandler = useCallback((key, value) => {
    variantHandler(prevState => ({
      ...prevState,
      [key]: value,
    }));

    setSelectedValues(prev => ({
      ...prev,
      [key]: value.description,
    }));
  }, [variantHandler]);

  const handleSelect = useCallback((key, choice) => {
    if (!choice.inStock || !choice.available) return;
    setSelectedValues(prev => {
      const newValue = prev[key] === choice.description ? '' : choice.description;
      const newVariantState = newValue ? choice : '';
      variantHandler(prevState => ({
        ...prevState,
        [key]: newVariantState,
      }));

      return {
        ...prev,
        [key]: newValue,
      };
    });
  }, [changeHandler, variantHandler]);

  useEffect(() => {
    if (variants?.length > 0) {
      setProductVariants(getMappedVariants(variants, availableChoices, selectedVariant, selectedValues))
    }
  }, [variants, selectedVariant, availableChoices, selectedValues]);

  useEffect(() => {
    // functionality for automatically select the first variant with non-zero quantity
    if (childListings.length > 0 && productVariants && !isFirstRender) {
      // Loop through child listings to find the first one with available stock
      let selectedChildListing = null;
      for (let i = 0; i < childListings.length; i++) {
        const currentChildListing = childListings[i];
        const quantity = currentChildListing?.currentStock?.attributes?.quantity;
  
        // If quantity is greater than 0, select this child listing
        if (quantity > 0) {
          selectedChildListing = currentChildListing;
          break;
        }
      }
  
      // If no child listing has a quantity > 0, select the first one
      if (!selectedChildListing) {
        selectedChildListing = childListings[0];
      }
  
      const firstChildListing = selectedChildListing?.attributes?.publicData?.choices || [];
  
      const filteredVariants = {};
  
      Object.keys(firstChildListing).forEach((variantType) => {
        const selectedValue = firstChildListing[variantType];
  
        // Find the corresponding variant group in productVariants (e.g., size, color, etc.)
        const variantGroup = productVariants[variantType.toLowerCase()]; // Ensure we match keys in lowercase (size, color)
  
        if (variantGroup) {
          filteredVariants[variantType.toLowerCase()] = variantGroup.filter(
            (option) => option.description === selectedValue || option.value === selectedValue
          );
        }
      });
  
      // Handle the variant selection for each filtered variant
      Object.keys(filteredVariants).map((filtered) => {
        handleSelect(filtered, filteredVariants[filtered][0]);
      });
  
      setIsFirstRender(true);
  }
  }, [productVariants, childListings]);


  const productVariantsSorted = productVariants.size ? { ...productVariants, size: sortSize(productVariants.size) } : productVariants;

if (!childListings.length || !productVariants) {
  return null;
}

// Ensure that productVariants has data
const variantTypes = Object.keys(productVariants || {}).sort();

return (
  <>
    {variantTypes.length > 0 && variantTypes.map((variantType) => {
      const variantTypeLabel = variantType.charAt(0).toUpperCase() + variantType.slice(1);
      const selectedVariantValue = selectedValues?.[variantType];

      // Pre-filter the choices to avoid repeating filter logic inside JSX
      const availableChoices = productVariantsSorted[variantType]?.filter(
        product => product.inStock && (!product.variantHavingNoStock || !product.variantHavingNoStock.includes(product.description))
      );
      return (
        <div key={variantType} className={styles.optionGroup}>
          <span className={styles.label}>
             {`${variantTypeLabel}: `} 
          </span>

          <div className={variantType === 'color' ? styles.colors : styles.sizes}>
            {availableChoices.map(choice => (
              variantType === 'color' ? (
                <div
                  key={choice.description}
                  className={`
                    ${styles.colorBorderOption} 
                    ${selectedVariant[variantType]?.value === choice.value ? styles.selected : ''}

                    ${!choice?.available ? `${styles.notAvailable} ${styles.notAvailableColor}` : ''}
                  `}
                  style={getBorderStyle(choice.value)}
                >
                  <div
                    className={`${styles.colorOption} ${!choice.inStock ? styles.outOfStock : ''}`}
                    style={getColorStyle(choice)}
                    onClick={() => handleSelect(variantType, choice)}
                  />
                </div>
              ) : (
                <button
                  type="button"
                  key={choice.value}
                  className={`
                    ${styles.sizeOption} 
                    ${selectedVariant[variantType]?.value === choice.value ? styles.selected : ''}
                    ${!choice?.available ? styles.notAvailable : ''}
                  `}
                  onClick={() => handleSelect(variantType, choice)}
                  disabled={!choice?.inStock}
                >
                  <div className={styles.borderMain} />
                  {choice?.description}
                </button>
              )
            ))}
          </div>
        </div>
      );
    })}
  </>
);

};

export default Variants;
